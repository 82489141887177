import './ArtistList.scss';
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArtistCard } from '../../components';
import SwiperCore from 'swiper/core';
import { Keyboard, Mousewheel } from 'swiper/modules';

export const ArtistList = (props: any) => {
  SwiperCore.use([Keyboard, Mousewheel]);
  return (
    <div className="artist-list">
      <Swiper
        grabCursor={true}
        spaceBetween={10}
        slidesPerView={'auto'}
        mousewheel={false}
        keyboard={true}
      >
        {props.artists &&
          props.artists.map((artist: any, i: number) => (
            <SwiperSlide key={i}>
              <ArtistCard item={artist} category={props.category} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
