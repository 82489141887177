import movieCard from '../../assets/movie_card.png';
import tvShowCard from '../../assets/tv_card.png';
import autographCard from '../../assets/autograph_card.png';
import artistCard from '../../assets/basic_user_2.png';
import { useState } from 'react';

export const LazyLoadBackgroundCard = (props: any) => {
  const [loaded, setLoaded] = useState(false);
  const handleLoad = () => {
    setLoaded(true);
  };
  const placeholder =
    props.type === 'movie'
      ? movieCard
      : props.type === 'tv-show' ||
          props.type === 'tv-show-season' ||
          props.type === 'episode'
        ? tvShowCard
        : props.type === 'autograph'
          ? autographCard
          : props.type === 'artist'
            ? artistCard
            : undefined;
  // const className = props.type === "movie" ? "movie-card" : props.type === "tv-show" ? "tv-show-card" : props.type === "tv-show-season" ? "tv-show-season-card" : props.type === "episode" ? "episode-card" : props.type === "autograph" ? "autograph-card" : props.type === "artist" ? "artist-card" : undefined;
  return (
    <div
      className={props.className}
      style={{
        backgroundImage: loaded
          ? `url(${props.background})`
          : `url(${placeholder})`,
        // filter: loaded ? "blur(0em)" : "blur(0.2em)",
        // transition: "filter 0.5s",
      }}
    >
      <img
        src={props.background}
        alt=""
        onLoad={handleLoad}
        style={{ display: 'none' }}
      />
      {props.children}
    </div>
  );
};
