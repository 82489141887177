import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import '../Detail/Detail.scss';
import {
  Button,
  OutlineButton,
  EpisodeList,
  TrailerModal,
  LoadingSpinner,
} from '../../components';
import { Link } from 'react-router-dom';
import { useGetTVShowSeasonQuery } from '../../app/services/tvShowServerApi';
import errorIcon from '../../assets/error.svg';
import { getOverviewFontSize, getTitleFontSize } from '../../shared/utils';
import backgroundIcon from '../../assets/background-icon.svg';

export const TVShowSeasonDetail = () => {
  const { category, id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category, id]);

  const {
    data: tvShowSeasonItem = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTVShowSeasonQuery(id);
  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const toggleTrailerModal = () => setOpenTrailerModal(!openTrailerModal);

  const [loadedBackground, setLoadedBackground] = useState(false);
  const handleLoadBackground = () => {
    setLoadedBackground(true);
  };
  const [loadedCover, setLoadedCover] = useState(false);
  const handleLoadCover = () => {
    setLoadedCover(true);
  };

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <>
          <img
            src={
              tvShowSeasonItem.background
                ? tvShowSeasonItem.background
                : tvShowSeasonItem.cover
            }
            alt=""
            onLoad={handleLoadBackground}
            style={{ display: 'none' }}
          />
          <img
            src={tvShowSeasonItem.cover}
            alt=""
            onLoad={handleLoadCover}
            style={{ display: 'none' }}
          />
          <div
            className="banner"
            style={{
              backgroundImage: loadedBackground
                ? `url(${tvShowSeasonItem.background ? tvShowSeasonItem.background : tvShowSeasonItem.cover})`
                : undefined,
              // filter: loaded ? "blur(0em)" : "blur(0.2em)",
              // transition: "filter 0.5s",
            }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(
                      `Season ${tvShowSeasonItem.number}`,
                    ),
                  }}
                >
                  Season {tvShowSeasonItem.number}
                </h1>
                <h3 className="subtitle">
                  <Link
                    className="media-a"
                    style={{ display: 'inline-block' }}
                    to={'/tv-shows/' + tvShowSeasonItem.tvShow.ulid}
                  >
                    {tvShowSeasonItem.tvShow.title}
                  </Link>
                </h3>

                <p
                  className="overview"
                  style={{
                    fontSize: getOverviewFontSize(
                      tvShowSeasonItem.description,
                    ),
                  }}
                >
                  {tvShowSeasonItem.description}
                </p>

                <div className="btns">
                  <OutlineButton onClick={toggleTrailerModal}>
                    Watch trailer
                  </OutlineButton>{' '}
                </div>
              </div>

              <div className="movie-content__poster">
                {loadedCover && (
                  <a
                    href={tvShowSeasonItem.coverOriginal}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="movie-content__poster__img_tv_show"
                      src={tvShowSeasonItem.cover}
                      alt={tvShowSeasonItem.title || tvShowSeasonItem.name}
                    />
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="movie-content-description">
            <p
              className="overview"
              style={{
                fontSize: '1rem',
              }}
            >
              {tvShowSeasonItem.description}
            </p>
          </div>

          <div className="detail-container seasons-list">
            <div className="media-section">
              <div className="media-section__header">
                <h2 className="subtitle">Episodes</h2>
              </div>
              <EpisodeList
                tvShowID={tvShowSeasonItem.tvShow}
                seasonID={tvShowSeasonItem.id}
                episodes={tvShowSeasonItem.episodes}
              />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Additional Information'}</h2>
              </div>
              <div className="genres">
                {tvShowSeasonItem?.network && (
                  <Link
                    className="media-a"
                    to={'/tv-shows/?network=' + tvShowSeasonItem.network.label}
                  >
                    <span className="badge genres__item">
                      Network: {tvShowSeasonItem.network.label}
                    </span>
                  </Link>
                )}
              </div>
              <div className="genres">
                <OutlineButton
                  onClick={() =>
                    window.open(tvShowSeasonItem.backgroundOriginal, '_blank')
                  }
                  className="secondary-button"
                >
                  <img src={backgroundIcon} width="25" height="25" alt="" />
                </OutlineButton>
              </div>
              {tvShowSeasonItem?.copyright && (
                <div className="genres">
                  <p className="overview">
                    Copyright: {tvShowSeasonItem.copyright}
                  </p>
                </div>
              )}
            </div>
          </div>
          <TrailerModal
            isOpen={openTrailerModal}
            toggle={toggleTrailerModal}
            item={tvShowSeasonItem}
          />
        </>
      )}
    </div>
  );
};
