import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import { useGetArtistTVShowDataQuery } from '../../app/services/tvShowServerApi';
import { OutlineButton } from '../Button';
import { LoadingSpinner } from '../LoadingSpinner';
import errorIcon from '../../assets/error.svg';
import { Link } from 'react-router-dom';

export const ArtistTVShowModal = (props: any) => {
  const [skipGetArtistTVShowData, setSkipGetArtistTVShowData] = useState(true);
  const {
    data = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetArtistTVShowDataQuery({
    artistId: props.artistId,
    tvShowId: props.tvShowId,
  },     {
    skip: skipGetArtistTVShowData,
  });

  const [currentSeason, setCurrentSeason] = useState<string | null>(null);

  const [availableSeasons, setAvailableSeasons] = useState<string[]>([]);

  useEffect(() => {
    const seasons = Object.keys(data);
    setAvailableSeasons(seasons);
    setCurrentSeason(seasons[0]);
  }, [data]);

  useEffect(() => {
    if (props.isOpen) {
      setSkipGetArtistTVShowData(false)
    }
  }, [props.isOpen]);

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        centered={true}
        className="modal-dialog modal-xl"
      >
        <div style={{ backgroundColor: '#0f0f0f', border: 'none' }}>
          <ModalHeader style={{ backgroundColor: '#0f0f0f', border: 'none' }}>
            <div
              className="media-modal__content__close"
              role="button"
              tabIndex={0}
              onKeyDown={props.toggle}
              onClick={props.toggle}
            >
              <i className="bi bi-x"></i>
            </div>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#0f0f0f' }}>
            {isLoading || isFetching ? (
              <div className="list-no-item-single">
                <LoadingSpinner />
              </div>
            ) : isError && !data ? (
              <div className="list-no-item-single">
                <img src={errorIcon} alt="error-icon" />
                <p>{error?.error}</p>
              </div>
            ) : (
              <Fragment>
                <h2 className="media-h2">Seasons</h2>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '20px',
                  }}
                >
                  {availableSeasons &&
                    availableSeasons.map((season: any, i: number) => (
                      <OutlineButton
                        key={i}
                        className="small"
                        onClick={() => setCurrentSeason(season)}
                      >
                        {season}
                      </OutlineButton>
                    ))}
                </div>
                <Table borderless dark responsive hover>
                  <thead>
                    <tr>
                      <th>Episode #</th>
                      <th>Title</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentSeason &&
                      data?.[currentSeason]?.map((item: any, index: number) => (
                        <tr key={index}>
                          <Link
                            className="media-a"
                            to={'/tv-shows/season/episode/' + item.ulid}
                            style={{ display: 'flex' }}
                          >
                            <th scope="row">{item.number}</th>
                          </Link>
                          <td>
                            <Link
                              className="media-a"
                              to={'/tv-shows/season/episode/' + item.ulid}
                              style={{ display: 'flex' }}
                            >
                              {item.title}
                            </Link>
                          </td>
                          <td>
                            <Link
                              className="media-a"
                              to={'/tv-shows/season/episode/' + item.ulid}
                              style={{ display: 'flex' }}
                            >
                              {item.roles}
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Fragment>
            )}
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};
