import { Button, LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
// import { useNavigate } from 'react-router';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback,
} from 'reactstrap';
import { useAppDispatch } from '../../../../app/hooks';
import {
  useGetTVShowSeasonEpisodeBackgroundQuery,
  usePatchTvShowSeasonEpisodeMutation,
} from '../../../../app/services/tvShowServerApi';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { IStep6FormInput } from '../../../../interfaces/forms/TVShowSeasonEpisodeForm';
import { Fragment } from 'react';
import errorIcon from '../../../../assets/error.svg';
import {
  setCurrentTvShowSeasonEpisodeStep,
  setNewEpisodeBackgroundVerticalPosition,
} from '../../../../app/slices/addTvShowSeasonEpisodeSlice';

export const Step6 = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    // data = {},
    isSuccess: isSuccessGetTVShowSeasonEpisodeBackground,
    isLoading: isLoadingGetTVShowSeasonEpisodeBackground,
    isFetching: isFetchingGetTVShowSeasonEpisodeBackground,
    isError: isErrorGetTVShowSeasonEpisodeBackground,
    error: errorGetTVShowSeasonEpisodeBackground,
  } = useGetTVShowSeasonEpisodeBackgroundQuery();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep6FormInput>();

  const [patchTvShowSeasonEpisode, { isLoading, isError, isSuccess, error }] =
    usePatchTvShowSeasonEpisodeMutation();

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1000,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  const onSubmit: SubmitHandler<IStep6FormInput> = (formdata) => {
    formdata.step = 'background_position_episode_data';
    // console.log("form data:", formdata)
    patchTvShowSeasonEpisode(formdata).then(function (result: any) {
      if (result && result.data) {
        // dispatch(setNewMovieId(result.data.movieId));
        dispatch(setCurrentTvShowSeasonEpisodeStep(7));
      }
    });
  };

  return (
    <Fragment>
      {isLoadingGetTVShowSeasonEpisodeBackground ||
      isFetchingGetTVShowSeasonEpisodeBackground ? (
        <div className="list-no-item-add">
          <LoadingSpinner />
        </div>
      ) : isErrorGetTVShowSeasonEpisodeBackground ? (
        <div className="list-no-item-add">
          <img src={errorIcon} alt="error-icon" />
          <p>{errorGetTVShowSeasonEpisodeBackground?.error}</p>
        </div>
      ) : isSuccessGetTVShowSeasonEpisodeBackground ? (
        <div className="big-form-container">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="backgroundVerticalPosition">
                    Background Vertical Position *
                  </Label>
                  <Controller
                    name="backgroundVerticalPosition"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="backgroundVerticalPosition"
                        placeholder="Enter the episode's background vertical position"
                        type="number"
                        step=".01"
                        invalid={!!errors.backgroundVerticalPosition}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          dispatch(
                            setNewEpisodeBackgroundVerticalPosition(
                              e.target.value,
                            ),
                          );
                          // console.log(e.target.value);
                        }}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The episodes's background vertical position is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        </div>
      ) : null}
    </Fragment>
  );
};
