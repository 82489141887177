import { mediaManagerServerApi } from './mediaManagerServerApi';

export const extendedApiSlice = mediaManagerServerApi.injectEndpoints({
  endpoints: (builder) => ({
    getArtists: builder.query<any, any | void>({
      query: (object) =>
        `artists/?page=${object?.page || 1}${object?.mode ? `&mode=${object?.mode}` : ''}${object?.keyword ? `&keyword=${object?.keyword}` : ''}`, //&page_size=12
      transformResponse: (rawResult: {
        results: any;
        currentPage: number;
        totalPages: number;
      }) => {
        return {
          results: rawResult.results,
          currentPage: rawResult.currentPage,
          totalPages: rawResult.totalPages,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }: { id: number }) => ({
                type: 'Artist' as const,
                id,
              })),
              'Artist',
            ]
          : ['Artist'],
      serializeQueryArgs: ({ queryArgs }) => {
        if (queryArgs) {
          const { page, ...otherArgs } = queryArgs;
          page;
          return otherArgs;
        } else {
          return queryArgs;
        }
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        //#1
        currentCache.results.push(...newItems.results);
        currentCache.currentPage = newItems.currentPage;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        //#1
        return currentArg?.page > previousArg?.page;
        //#2
        // return currentArg?.page !== previousArg?.page;
      },
    }),

    getArtist: builder.query<any, string | undefined>({
      query: (id) => `artist/artists/${id}/`,
      providesTags: (result, error, arg) => [{ type: 'Artist', id: arg }],
    }),

    getImdbArtistOptions: builder.query<any, any | void>({
      query: (object) =>
        `artists/get_imdb_artist_options/?artist_name=${object.artistName}`,
      // transformResponse: (rawResult: {
      //   imdbSearchArtistResults: any;
      // }) => {
      //   const processedImdbSearchResults =
      //     rawResult.imdbSearchArtistResults.map((searchResult: any) => {
      //       return {
      //         title: searchResult.Title,
      //         cover: searchResult.Poster,
      //         id: searchResult.imdbID,
      //       };
      //     });
      //   return {
      //     imdbSearchMovieResults: processedImdbSearchResults,
      //   };
      // },
    }),
  }),
});

export const {
  useGetArtistsQuery,
  useGetArtistQuery,
  useGetImdbArtistOptionsQuery,
} = extendedApiSlice;
