import { LazyLoadBackgroundCard } from '../LazyLoadBackgroundCard';
import './SelectableTVShowCard.scss';

export const SelectableTVShowCard = (props: any) => {
  return (
    <>
      {/* <div
        className={`selectable-tv-show-card ${props.selected ? 'selected' : null}`}
        style={{ backgroundImage: `url(${props.item.cover})` }}
      /> */}
      <LazyLoadBackgroundCard
        type={'tv-show'}
        background={props.item.cover}
        className={`selectable-tv-show-card ${props.selected ? 'selected' : null}`}
      />
      <h3 className="media-h3">{props.item.title}</h3>
    </>
  );
};
