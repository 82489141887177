import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useEffect } from 'react';
import {
  resetMovieState,
  setCurrentMovieStep,
  setSelectedMediaType,
} from '../../../../app/slices/addMediaSlice';

export const MovieStep12 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const newMovieUlid = useAppSelector(
    (state: RootState) => state.addMedia.newMovieUlid,
  );

  const addMovieSetup = () => {
    dispatch(setCurrentMovieStep(1));
    dispatch(setSelectedMediaType('movie'));
  };

  const finalSetup = () => {
    navigate(`/movies/${newMovieUlid}`);
    dispatch(resetMovieState());
  };
  return (
    <div className="add-media-form-container">
      <div className="buttons-container">
        <Button onClick={() => addMovieSetup()}>Add Another Movie</Button>
        <Button onClick={() => finalSetup()}>Go To New Movie Page</Button>
      </div>
    </div>
  );
};
