import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import '../Detail/Detail.scss';
import {
  Button,
  OutlineButton,
  ArtistList,
  TrailerModal,
  LoadingSpinner,
  AutographList,
  MovieCard,
  PhotoList,
  DocumentList,
} from '../../components';
import backgroundIcon from '../../assets/background-icon.svg';
import { Link } from 'react-router-dom';
import errorIcon from '../../assets/error.svg';
import { getTitleFontSize } from '../../shared/utils';
import { useGetAutographQuery } from '../../app/services/autographServerApi';

/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore from 'swiper/core';
// import { Keyboard, Mousewheel } from 'swiper/modules';
import { VideoList } from '../../components/VideoList';

export const AutographDetail = () => {
  const { category, id } = useParams();
  const {
    data: autographItem = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetAutographQuery(id);

  const background = autographItem.background
    ? autographItem.background
    : autographItem.cover
      ? autographItem.cover
      : autographItem.image;
  const renderSimilarItems = () => {
    return (
      <AutographList
        category={category}
        mode="similar"
        ulid={autographItem.ulid}
      />
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category, id]);

  // const [openAutographModal, setOpenAutographModal] = useState(false);
  // const toggleAutographModal = () =>
  //   setOpenAutographModal(!openAutographModal);

  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const toggleTrailerModal = () => setOpenTrailerModal(!openTrailerModal);

  const getAutographNames = (autographItem: any) => {
    const artistsLength = autographItem.autographArtists.length;
    if (
      !autographItem.title.includes(
        autographItem.autographArtists[0].artist.name,
      )
    ) {
      return (
        <h3 className="subtitle">
          {autographItem.autographArtists.map(
            (autographArtist: any, index: number) => (
              <div style={{ display: 'inline-flex' }} key={index}>
                <Link
                  className="media-a"
                  style={{ display: 'inline-block' }}
                  to={'/artists/' + autographArtist.artist.ulid}
                >
                  <h3 className="subtitle">{autographArtist.artist.name}</h3>
                </Link>
                <h3 className="subtitle" style={{ whiteSpace: 'pre-wrap' }}>
                  {index === artistsLength - 1
                    ? ''
                    : index === artistsLength - 2
                      ? ' & '
                      : ', '}
                </h3>
              </div>
            ),
          )}
        </h3>
      );
    } else {
      return null;
    }
  };

  const getAutographRoles = (autographItem: any) => {
    const artistsLength = autographItem.autographArtists.length;
    if (autographItem?.movie || autographItem?.tvShow) {
      return (
        <h3 className="subtitle">
          as {''}
          {autographItem.autographArtists.map(
            (autographArtist: any, index: number) => (
              <div style={{ display: 'inline-flex' }} key={index}>
                <h3 className="subtitle">{autographArtist.role}</h3>
                <h3 className="subtitle" style={{ whiteSpace: 'pre-wrap' }}>
                  {index === artistsLength - 1
                    ? ''
                    : index === artistsLength - 2
                      ? ' & '
                      : ', '}
                </h3>
              </div>
            ),
          )}
        </h3>
      );
    } else {
      return null;
    }
  };

  const getAutographMedia = (autographItem: any) => {
    if (autographItem?.movie || autographItem?.tvShow) {
      return (
        <h3 className="subtitle">
          in {''}
          <Link
            className="media-a"
            style={{ display: 'inline-block' }}
            to={
              autographItem?.movie
                ? '/movies/' + autographItem?.movie?.ulid
                : '/tv-shows/' + autographItem?.tvShow?.ulid
            }
          >
            {autographItem?.movie
              ? autographItem?.movie?.title
              : autographItem?.tvShow?.title}
          </Link>
        </h3>
      );
    } else {
      return null;
    }
  };

  const [loadedBackground, setLoadedBackground] = useState(false);
  const handleLoadBackground = () => {
    setLoadedBackground(true);
  };
  const [loadedCover, setLoadedCover] = useState(false);
  const handleLoadCover = () => {
    setLoadedCover(true);
  };

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <>
          <img
            src={background}
            alt=""
            onLoad={handleLoadBackground}
            style={{ display: 'none' }}
          />
          <img
            src={autographItem.cover}
            alt=""
            onLoad={handleLoadCover}
            style={{ display: 'none' }}
          />
          <div
            className="banner"
            style={{
              backgroundImage: loadedBackground
                ? `url(${background})`
                : undefined,
              backgroundPosition: `${50}% ${autographItem.backgroundVerticalPosition}%`,
              // filter: loaded ? "blur(0em)" : "blur(0.2em)",
              // transition: "filter 0.5s",
            }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(autographItem.title),
                  }}
                >
                  {autographItem.title.includes(
                    autographItem.autographArtists[0].artist.name,
                  ) ? (
                    <Link
                      className="media-a"
                      style={{ display: 'inline-block' }}
                      to={
                        '/artists/' +
                        autographItem.autographArtists[0].artist.ulid
                      }
                    >
                      {autographItem.title}
                    </Link>
                  ) : (
                    <Fragment>{autographItem.title}</Fragment>
                  )}
                </h1>
                {getAutographNames(autographItem)}
                {getAutographRoles(autographItem)}
                {getAutographMedia(autographItem)}

                {/* <div className="genres">
                  <span className="badge genres__item">
                    {'movieItem.releaseDate'}
                  </span>
                </div>
                <p
                  className="overview"
                  style={{
                    fontSize: getOverviewFontSize('movieItem.description'),
                  }}
                >
                  {'movieItem.description'}
                </p> */}

                {/* <div className="btns">
                  <Button onClick={toggleMovieModal}>Watch now</Button>
                  <OutlineButton onClick={toggleTrailerModal}>
                    Watch trailer
                  </OutlineButton>
                </div> */}
              </div>

              <div className="movie-content__poster">
                {loadedCover && (
                  <a
                    href={autographItem.coverOriginal}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {autographItem.orientation === 1 ? (
                      <img
                        className="movie-content__poster__img_autograph_horizontal"
                        src={autographItem.cover}
                        alt={autographItem.title || autographItem.name}
                      />
                    ) : (
                      <img
                        className="movie-content__poster__img_autograph_vertical"
                        src={autographItem.cover}
                        alt={autographItem.title || autographItem.name}
                      />
                    )}
                  </a>
                )}
              </div>
            </div>
          </div>
          {/* <div className="movie-content-description">
            <p
              className="overview"
              style={{
                fontSize: '1rem',
              }}
            >
              {'movieItem.description'}
            </p>
          </div> */}

          {autographItem.autographArtists.length > 0 && (
            <div className="movie-content-details">
              <div className="movie-content-details__info">
                <div className="media-section__header">
                  <h2 className="subtitle">{'Artists'}</h2>
                </div>
                <ArtistList
                  artists={autographItem.autographArtists}
                  category="artist"
                />
              </div>

              {autographItem.movie ? (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Movie'}</h2>
                  </div>
                  {/* <ArtistList
                    artists={movieItem.movieActors}
                    category="actor"
                  /> */}
                  {/* <MovieList category={category} mode="similar" ulid={autographItem.movie.ulid} /> */}
                  <div className="artist-list">
                    <Swiper
                      grabCursor={true}
                      spaceBetween={10}
                      slidesPerView={'auto'}
                      mousewheel={false}
                      keyboard={true}
                    >
                      <SwiperSlide>
                        <MovieCard
                          item={autographItem.movie}
                          category={'movies'}
                        />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              ) : autographItem.tvShow ? (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'TV Show'}</h2>
                  </div>
                  {/* <ArtistList
                  artists={movieItem.movieActors}
                  category="actor"
                /> */}
                  {/* <MovieList category={category} mode="similar" ulid={autographItem.movie.ulid} /> */}
                  <div className="artist-list">
                    <Swiper
                      grabCursor={true}
                      spaceBetween={10}
                      slidesPerView={'auto'}
                      mousewheel={false}
                      keyboard={true}
                    >
                      <SwiperSlide>
                        <MovieCard
                          item={autographItem.tvShow}
                          category={'tv-shows'}
                        />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              ) : null}
            </div>
          )}

          {autographItem.autographImages.length > 0 && (
            <div className="detail-container seasons-list">
              <div className="media-section">
                <div className="media-section__header">
                  <h2 className="subtitle">Photos</h2>
                </div>
                <PhotoList
                  // tvShowID={tvShowSeasonItem.tvShow}
                  // seasonID={tvShowSeasonItem.id}
                  photos={autographItem.autographImages}
                />
              </div>
            </div>
          )}

          {autographItem.autographVideos.length > 0 && (
            <div className="detail-container seasons-list">
              <div className="media-section">
                <div className="media-section__header">
                  <h2 className="subtitle">Videos</h2>
                </div>
                <VideoList
                  // tvShowID={tvShowSeasonItem.tvShow}
                  // seasonID={tvShowSeasonItem.id}
                  videos={autographItem.autographVideos}
                />
              </div>
            </div>
          )}

          {autographItem.autographDocuments.length > 0 && (
            <div className="detail-container seasons-list">
              <div className="media-section">
                <div className="media-section__header">
                  <h2 className="subtitle">Documents</h2>
                </div>
                <DocumentList
                  // tvShowID={tvShowSeasonItem.tvShow}
                  // seasonID={tvShowSeasonItem.id}
                  documents={autographItem.autographDocuments}
                />
              </div>
            </div>
          )}

          {autographItem.autographAuthenticators.length > 0 && (
            <Fragment>
              <div className="movie-content-details">
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Authenticators'}</h2>
                  </div>
                </div>
              </div>
              {autographItem.autographAuthenticators.map(
                (autographAuthenticator: any, i: number) => (
                  <div key={i}>
                    <div className="movie-content-details">
                      <div className="movie-content-details__info">
                        <div className="media-section__header">
                          <h2 className="subtitle">
                            {autographAuthenticator.authenticator.label}
                          </h2>
                        </div>
                        {autographAuthenticator?.coaCode && (
                          <div className="media-section__header">
                            <h2 className="subtitle">{`COA Code: ${autographAuthenticator.coaCode}`}</h2>
                          </div>
                        )}
                        {autographAuthenticator?.coaUrl && (
                          <div className="media-section__header">
                            <h2 className="subtitle">
                              <Link
                                className="media-a"
                                style={{ display: 'inline-block' }}
                                to={autographAuthenticator.coaUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <h3 className="subtitle">{'COA URL'}</h3>
                              </Link>
                            </h2>
                          </div>
                        )}
                      </div>
                    </div>

                    {(autographAuthenticator?.coaImages.length > 0 ||
                      autographAuthenticator?.coaDocuments.length > 0) && (
                      <div className="movie-content-details" key={i}>
                        {autographAuthenticator?.coaImages.length > 0 && (
                          <div className="movie-content-details__info">
                            <div className="media-section__header">
                              <h2 className="subtitle">{'Photos'}</h2>
                            </div>
                            <PhotoList
                              photos={autographAuthenticator.coaImages}
                              mode={'coa'}
                            />
                          </div>
                        )}
                        {autographAuthenticator?.coaDocuments.length > 0 && (
                          <div className="movie-content-details__info">
                            <div className="media-section__header">
                              <h2 className="subtitle">{'Documents'}</h2>
                            </div>
                            <DocumentList
                              documents={autographAuthenticator.coaDocuments}
                              mode={'coa'}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ),
              )}
            </Fragment>
          )}

          {/* {(movieItem.movieProducers.length > 0 ||
            movieItem.movieScreenwriters.length > 0) && (
            <div className="movie-content-details">
              {movieItem.movieProducers.length > 0 && (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Producers'}</h2>
                  </div>
                  <ArtistList
                    artists={movieItem.movieProducers}
                    category="producer"
                  />
                </div>
              )}
              {movieItem.movieScreenwriters.length > 0 && (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Screenwriters'}</h2>
                  </div>
                  <ArtistList
                    artists={movieItem.movieScreenwriters}
                    category="screenwriter"
                  />
                </div>
              )}
            </div>
          )} */}
          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Signature Information'}</h2>
              </div>

              <div className="genres">
                <Link
                  className="media-a"
                  to={'/autographs/?size=' + autographItem.size.label}
                >
                  <span className="badge genres__item">
                    Size: {autographItem.size.label}
                  </span>
                </Link>
                <Link
                  className="media-a"
                  to={'/autographs/?signed_type=' + autographItem.signedType}
                >
                  <span className="badge genres__item">
                    Signed Type: {autographItem.signedType}
                  </span>
                </Link>
                <Link
                  className="media-a"
                  to={
                    '/autographs/?picture_type=' +
                    autographItem.pictureType.label
                  }
                >
                  <span className="badge genres__item">
                    Picture Type: {autographItem.pictureType.label}
                  </span>
                </Link>
              </div>

              {autographItem?.dateSigned && (
                // <div className="genres">
                <p className="overview">
                  {autographItem?.exactDate
                    ? 'Exact Date Signed'
                    : 'Approximate Date Signed'}
                  : {autographItem.dateSigned}
                </p>
                // </div>
              )}
              {autographItem?.placeSigned && (
                // <div className="genres">
                <p className="overview">
                  Place Signed: {autographItem.placeSigned}
                </p>
                // </div>
              )}
            </div>

            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Additional Information'}</h2>
              </div>

              {/* <div className="genres">
                {movieItem?.studio && (
                  <Link
                    className="media-a"
                    to={'/movies/?studio=' + movieItem.studio.label}
                  >
                    <span className="badge genres__item">
                      Studio: {movieItem.studio.label}
                    </span>
                  </Link>
                )}
              </div> */}

              <div className="genres">
                <OutlineButton
                  onClick={() =>
                    window.open(autographItem.coverOriginal, '_blank')
                  }
                  className="secondary-button"
                >
                  <img src={backgroundIcon} width="25" height="25" alt="" />
                </OutlineButton>
              </div>

              {autographItem.tags.length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Tags'}</h2>
                  </div>
                  <div className="genres">
                    {autographItem.tags.map((tag: any, i: number) => (
                      <Link
                        className="media-a"
                        to={'/autographs/?tag=' + tag.label}
                        key={i}
                      >
                        <span key={i} className="badge genres__item">
                          {tag.label}
                        </span>
                      </Link>
                    ))}
                  </div>
                </Fragment>
              )}
              {autographItem.collections.length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Collections'}</h2>
                  </div>
                  <div className="genres">
                    {autographItem.collections.map(
                      (collection: any, i: number) => (
                        <Link
                          className="media-a"
                          to={'/autographs/?collection=' + collection.label}
                          key={i}
                        >
                          <span key={i} className="badge genres__item">
                            {collection.label}
                          </span>
                        </Link>
                      ),
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </div>

          <div className="detail-container">
            <div className="media-section mt-3">
              <div className="media-section__header">
                <h2 className="subtitle">Similar</h2>
              </div>
              {renderSimilarItems()}
            </div>
          </div>

          <TrailerModal
            isOpen={openTrailerModal}
            toggle={toggleTrailerModal}
            item={autographItem}
          />
          {/* <MediaModal
            isOpen={openAutographModal}
            toggle={toggleAutographModal}
            item={autographItem}
            userItem={movieUserItem}
            category={'autograph'}
          /> */}
        </>
      )}
    </div>
  );
};
