import './AutographList.scss';
/* eslint-disable import/no-unresolved */
import { SwiperSlide, Swiper } from 'swiper/react';
import { AutographCard, LoadingSpinner } from '../';
import SwiperCore from 'swiper/core';
import { Keyboard, Mousewheel } from 'swiper/modules';
import {
  useGetAutographsQuery,
  useGetSimilarAutographsQuery,
} from '../../app/services/autographServerApi';
import errorIcon from '../../assets/error.svg';
import noDataIcon from '../../assets/nodata.svg';

export const AutographList = (props: any) => {
  SwiperCore.use([Keyboard, Mousewheel]);
  const {
    data: autographsData = {},
    isLoading: isLoadingAutographs,
    // isSuccess,
    // isFetching,
    isError: isErrorAutographs,
    error: errorAutographs,
  } = useGetAutographsQuery(
    { page: 1, mode: 'home' },
    {
      skip: props?.mode === 'similar',
    },
  );

  const {
    data: similarAutographsData = {},
    isLoading: isLoadingSimilarAutographs,
    // isSuccessSimilar,
    // isFetchingSimilar,
    isError: isErrorSimilarAutographs,
    error: errorSimilarAutographs,
  } = useGetSimilarAutographsQuery(
    { page: 1, mode: 'similar', id: props.ulid },
    {
      skip: props?.mode !== 'similar',
    },
  );

  const data =
    props?.mode === 'similar' ? similarAutographsData : autographsData;
  const isLoading =
    props?.mode === 'similar'
      ? isLoadingSimilarAutographs
      : isLoadingAutographs;
  const isError =
    props?.mode === 'similar' ? isErrorSimilarAutographs : isErrorAutographs;
  const error =
    props?.mode === 'similar' ? errorSimilarAutographs : errorAutographs;

  return (
    <div className="autograph-list">
      {isLoading ? (
        <div className="list-no-item">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : data.results.length === 0 ? (
        <div className="list-no-item">
          <img src={noDataIcon} alt="no-item-icon" />
          <p>{'No Items'}</p>
        </div>
      ) : (
        <Swiper
          grabCursor={true}
          spaceBetween={10}
          slidesPerView={'auto'}
          mousewheel={false}
          keyboard={true}
        >
          {data.results.map((item: any, i: number) => (
            <SwiperSlide key={i}>
              <AutographCard item={item} category={props.category} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
