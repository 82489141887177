import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import '../Detail/Detail.scss';
import {
  Button,
  OutlineButton,
  ArtistList,
  TrailerModal,
  MediaModal,
  LoadingSpinner,
  MediaModal2,
} from '../../components';
import { Link } from 'react-router-dom';
import {
  useGetEpisodeUserQuery,
  useGetTVShowSeasonEpisodeQuery,
  useSaveEpisodeUserMutation,
} from '../../app/services/tvShowServerApi';
import errorIcon from '../../assets/error.svg';
import {
  getLanguagesWithAudio,
  getLanguagesWithSubtitle,
  getMainAttribute,
  getOriginalTitleFontSize,
  getOverviewFontSize,
  getTitleFontSize,
} from '../../shared/utils';
import { isSafari } from 'react-device-detect';
import copyIcon from '../../assets/copy-icon.svg';
import eyeIcon from '../../assets/eye-icon.svg';
import eyeFilledIcon from '../../assets/eye-filled-icon.svg';
import backgroundIcon from '../../assets/background-icon.svg';

export const TVShowEpisodeDetail = () => {
  const { category, id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category, id]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(tvShowSeasonEpisodeItem?.filePath);
    alert('Movie link copied to clipboard');
  };

  const {
    data: tvShowSeasonEpisodeItem = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTVShowSeasonEpisodeQuery(id);

  const {
    data: episodeUserItem = {},
    // isLoading,
    // // isFetching,
    isError: isErrorEpisodeUserItem,
    // error,
  } = useGetEpisodeUserQuery(id);

  const [openTVShowSeasonEpisodeModal, setOpenTVShowSeasonEpisodeModal] =
    useState(false);
  const toggleTVShowSeasonEpisodeModal = () =>
    setOpenTVShowSeasonEpisodeModal(!openTVShowSeasonEpisodeModal);

  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const toggleTrailerModal = () => setOpenTrailerModal(!openTrailerModal);

  const [saveEpisodeUser] = useSaveEpisodeUserMutation();

  const markAsWatched = (watched: boolean) => {
    const data = { id: id, watched: watched };
    saveEpisodeUser(data);
  };

  const background = tvShowSeasonEpisodeItem.background
    ? tvShowSeasonEpisodeItem.background
    : tvShowSeasonEpisodeItem.cover
      ? tvShowSeasonEpisodeItem.cover
      : tvShowSeasonEpisodeItem.image;

  const [loadedBackground, setLoadedBackground] = useState(false);
  const handleLoadBackground = () => {
    setLoadedBackground(true);
  };
  const [loadedCover, setLoadedCover] = useState(false);
  const handleLoadCover = () => {
    setLoadedCover(true);
  };

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <>
          <img
            src={background}
            alt=""
            onLoad={handleLoadBackground}
            style={{ display: 'none' }}
          />
          <img
            src={tvShowSeasonEpisodeItem.cover}
            alt=""
            onLoad={handleLoadCover}
            style={{ display: 'none' }}
          />
          <div
            className="banner"
            style={{
              backgroundImage: loadedBackground
                ? `url(${background})`
                : undefined,
              // filter: loaded ? "blur(0em)" : "blur(0.2em)",
              // transition: "filter 0.5s",
            }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(
                      tvShowSeasonEpisodeItem.title ||
                        tvShowSeasonEpisodeItem.name,
                    ),
                  }}
                >
                  {tvShowSeasonEpisodeItem.title ||
                    tvShowSeasonEpisodeItem.name}
                </h1>
                {tvShowSeasonEpisodeItem.originalTitle ? (
                  <h3
                    className="original-title"
                    style={{
                      fontSize: getOriginalTitleFontSize(
                        tvShowSeasonEpisodeItem.originalTitle,
                      ),
                    }}
                  >
                    Original Title: {tvShowSeasonEpisodeItem.originalTitle}
                  </h3>
                ) : null}
                <h3 className="subtitle">
                  <Link
                    className="media-a"
                    style={{ display: 'inline-block' }}
                    to={
                      '/tv-shows/season/' + tvShowSeasonEpisodeItem.season.ulid
                    }
                  >
                    Season {tvShowSeasonEpisodeItem?.season?.number}
                  </Link>
                </h3>
                {/* <br/> */}
                <h3 className="subtitle">
                  <Link
                    className="media-a"
                    style={{ display: 'inline-block' }}
                    to={
                      '/tv-shows/' + tvShowSeasonEpisodeItem.season.tvShow.ulid
                    }
                  >
                    {tvShowSeasonEpisodeItem?.season?.tvShow?.title}
                  </Link>
                </h3>

                <div className="genres">
                  <span className="badge genres__item">
                    {tvShowSeasonEpisodeItem.releaseDate}
                  </span>
                  <span className="badge genres__item">
                    {tvShowSeasonEpisodeItem.runtime}
                  </span>
                  <Link
                    className="media-a"
                    to={
                      '/tv-shows/?quality=' +
                      tvShowSeasonEpisodeItem.quality.label
                    }
                  >
                    <span className="badge genres__item">
                      {tvShowSeasonEpisodeItem.quality.label}
                    </span>
                  </Link>
                  <Link
                    className="media-a"
                    to={
                      '/tv-shows/?language=' +
                      getMainAttribute(
                        tvShowSeasonEpisodeItem.episodeLanguages,
                      )?.language?.label
                    }
                  >
                    <span className="badge genres__item">
                      {
                        getMainAttribute(
                          tvShowSeasonEpisodeItem.episodeLanguages,
                        )?.language?.label
                      }
                    </span>
                  </Link>
                </div>

                <p
                  className="overview"
                  style={{
                    fontSize: getOverviewFontSize(
                      tvShowSeasonEpisodeItem.description,
                    ),
                  }}
                >
                  {tvShowSeasonEpisodeItem.description}
                </p>

                <div className="btns">
                  <Button onClick={toggleTVShowSeasonEpisodeModal}>
                    Watch now
                  </Button>
                  <OutlineButton onClick={toggleTrailerModal}>
                    Watch trailer
                  </OutlineButton>{' '}
                </div>
              </div>

              <div className="movie-content__poster">
                {loadedCover && (
                  <a
                    href={tvShowSeasonEpisodeItem.coverOriginal}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="movie-content__poster__img_tv_show"
                      src={tvShowSeasonEpisodeItem.cover}
                      alt={
                        tvShowSeasonEpisodeItem.title ||
                        tvShowSeasonEpisodeItem.name
                      }
                    />
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="movie-content-description">
            {tvShowSeasonEpisodeItem.originalTitle ? (
              <h3
                className="original-title"
                style={{
                  fontSize: getOriginalTitleFontSize(
                    tvShowSeasonEpisodeItem.originalTitle,
                  ),
                }}
              >
                Original Title: {tvShowSeasonEpisodeItem.originalTitle}
              </h3>
            ) : null}
            <p
              className="overview"
              style={{
                fontSize: '1rem',
              }}
            >
              {tvShowSeasonEpisodeItem.description}
            </p>
          </div>

          {(tvShowSeasonEpisodeItem.episodeDirectors.length ||
            tvShowSeasonEpisodeItem.episodeActors.length) && (
            <div className="movie-content-details">
              {tvShowSeasonEpisodeItem.episodeDirectors.length > 0 && (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Directors'}</h2>
                  </div>
                  <ArtistList
                    artists={tvShowSeasonEpisodeItem.episodeDirectors}
                    category="director"
                  />
                </div>
              )}
              {tvShowSeasonEpisodeItem.episodeActors.length > 0 && (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Cast'}</h2>
                  </div>
                  <ArtistList
                    artists={tvShowSeasonEpisodeItem.episodeActors}
                    category="actor"
                  />
                </div>
              )}
            </div>
          )}
          {(tvShowSeasonEpisodeItem.episodeProducers.length > 0 ||
            tvShowSeasonEpisodeItem.episodeScreenwriters.length > 0) && (
            <div className="movie-content-details">
              {tvShowSeasonEpisodeItem.episodeProducers.length > 0 && (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Producers'}</h2>
                  </div>
                  <ArtistList
                    artists={tvShowSeasonEpisodeItem.episodeProducers}
                    category="producer"
                  />
                </div>
              )}
              {tvShowSeasonEpisodeItem.episodeScreenwriters.length > 0 && (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Screenwriters'}</h2>
                  </div>
                  <ArtistList
                    artists={tvShowSeasonEpisodeItem.episodeScreenwriters}
                    category="screenwriter"
                  />
                </div>
              )}
            </div>
          )}

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              {getLanguagesWithAudio(tvShowSeasonEpisodeItem.episodeLanguages)
                .length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Languages'}</h2>
                  </div>
                  <div className="genres">
                    {getLanguagesWithAudio(
                      tvShowSeasonEpisodeItem.episodeLanguages,
                    ).map((episodeLanguage: any, i: number) => (
                      <Link
                        className="media-a"
                        to={
                          '/tv-shows/?language=' +
                          episodeLanguage.language.label
                        }
                        key={i}
                      >
                        <span className="badge genres__item">
                          {episodeLanguage.language.label}
                        </span>
                      </Link>
                    ))}
                  </div>
                </Fragment>
              )}

              {getLanguagesWithSubtitle(
                tvShowSeasonEpisodeItem.episodeLanguages,
              ).length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Subtitles'}</h2>
                  </div>
                  <div className="genres">
                    {getLanguagesWithSubtitle(
                      tvShowSeasonEpisodeItem.episodeLanguages,
                    ).map((episodeLanguage: any, i: number) => (
                      <Link
                        className="media-a"
                        to={
                          '/tv-shows/?subtitle=' +
                          episodeLanguage.language.label
                        }
                        key={i}
                      >
                        <span className="badge genres__item">
                          {episodeLanguage.language.label}
                        </span>
                      </Link>
                    ))}
                  </div>
                </Fragment>
              )}
            </div>

            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Additional Information'}</h2>
              </div>

              <div className="genres">
                <span className="badge genres__item">
                  IMDB Rating: {tvShowSeasonEpisodeItem.imdbRating}
                </span>
              </div>

              <div className="genres">
                <OutlineButton
                  onClick={() => copyToClipboard()}
                  className="secondary-button"
                >
                  <img src={copyIcon} width="25" height="25" alt="" />
                </OutlineButton>
                <OutlineButton
                  onClick={() =>
                    window.open(
                      tvShowSeasonEpisodeItem.coverOriginal,
                      '_blank',
                    )
                  }
                  className="secondary-button"
                >
                  <img src={backgroundIcon} width="25" height="25" alt="" />
                </OutlineButton>

                <OutlineButton
                  onClick={() =>
                    markAsWatched(
                      !isErrorEpisodeUserItem && episodeUserItem?.watched
                        ? false
                        : true,
                    )
                  }
                  className="secondary-button"
                >
                  <img
                    src={
                      !isErrorEpisodeUserItem && episodeUserItem?.watched
                        ? eyeFilledIcon
                        : eyeIcon
                    }
                    width="25"
                    height="25"
                    alt=""
                  />
                </OutlineButton>
              </div>
            </div>
          </div>

          <TrailerModal
            isOpen={openTrailerModal}
            toggle={toggleTrailerModal}
            item={tvShowSeasonEpisodeItem}
          />
          {getLanguagesWithAudio(tvShowSeasonEpisodeItem.episodeLanguages)
            .length > 1 && isSafari ? (
            <MediaModal
              isOpen={openTVShowSeasonEpisodeModal}
              toggle={toggleTVShowSeasonEpisodeModal}
              item={tvShowSeasonEpisodeItem}
              userItem={episodeUserItem}
            />
          ) : (
            <MediaModal2
              isOpen={openTVShowSeasonEpisodeModal}
              toggle={toggleTVShowSeasonEpisodeModal}
              item={tvShowSeasonEpisodeItem}
              userItem={episodeUserItem}
            />
          )}
        </>
      )}
    </div>
  );
};
