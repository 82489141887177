import { useParams } from 'react-router';
import '../Detail/Detail.scss';
import {
  TVShowList,
  TVShowSeasonList,
  LoadingSpinner,
  Button,
  OutlineButton,
} from '../../components';
import { Link } from 'react-router-dom';
import { useGetTVShowQuery } from '../../app/services/tvShowServerApi';
import errorIcon from '../../assets/error.svg';
import {
  getMainAttribute,
  getOriginalTitleFontSize,
  getOverviewFontSize,
  getTitleFontSize,
} from '../../shared/utils';
import backgroundIcon from '../../assets/background-icon.svg';
import { Fragment, useEffect, useState } from 'react';

export const TVShowDetail = () => {
  const { category, id } = useParams();
  const {
    data: tvShowItem = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTVShowQuery(id);

  const background = tvShowItem.background
    ? tvShowItem.background
    : tvShowItem.cover
      ? tvShowItem.cover
      : tvShowItem.image;

  const renderSimilarItems = () => {
    return (
      <TVShowList category={category} mode="similar" ulid={tvShowItem.ulid} />
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category, id]);

  const [loadedBackground, setLoadedBackground] = useState(false);
  const handleLoadBackground = () => {
    setLoadedBackground(true);
  };
  const [loadedCover, setLoadedCover] = useState(false);
  const handleLoadCover = () => {
    setLoadedCover(true);
  };

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <>
          <img
            src={background}
            alt=""
            onLoad={handleLoadBackground}
            style={{ display: 'none' }}
          />
          <img
            src={tvShowItem.cover}
            alt=""
            onLoad={handleLoadCover}
            style={{ display: 'none' }}
          />
          <div
            className="banner"
            style={{
              backgroundImage: loadedBackground
                ? `url(${background})`
                : undefined,
              // filter: loaded ? "blur(0em)" : "blur(0.2em)",
              // transition: "filter 0.5s",
            }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(
                      tvShowItem.title || tvShowItem.name,
                    ),
                  }}
                >
                  {tvShowItem.title || tvShowItem.name}
                </h1>
                {tvShowItem.originalTitle ? (
                  <h3
                    className="original-title"
                    style={{
                      fontSize: getOriginalTitleFontSize(
                        tvShowItem.originalTitle,
                      ),
                    }}
                  >
                    Original Title: {tvShowItem.originalTitle}
                  </h3>
                ) : null}
                <div className="genres">
                  <span className="badge genres__item">{tvShowItem.year}</span>
                  <Link
                    className="media-a"
                    to={
                      '/tv-shows/?genre=' +
                      getMainAttribute(tvShowItem.tvShowGenres)?.genre?.label
                    }
                  >
                    <span className="badge genres__item">
                      {getMainAttribute(tvShowItem.tvShowGenres)?.genre?.label}
                    </span>
                  </Link>
                  <Link
                    className="media-a"
                    to={'/tv-shows/?rating=' + tvShowItem.rating.label}
                  >
                    <span className="badge genres__item">
                      {tvShowItem.rating.label}
                    </span>
                  </Link>
                  <Link
                    className="media-a"
                    to={
                      '/tv-shows/?country=' +
                      getMainAttribute(tvShowItem.tvShowCountries)?.country
                        ?.label
                    }
                  >
                    <span className="badge genres__item">
                      {
                        getMainAttribute(tvShowItem.tvShowCountries)?.country
                          ?.label
                      }
                    </span>
                  </Link>
                </div>
                <p
                  className="overview"
                  style={{
                    fontSize: getOverviewFontSize(tvShowItem.description),
                  }}
                >
                  {tvShowItem.description}
                </p>
              </div>
              <div className="movie-content__poster">
                {loadedCover && (
                  <a
                    href={tvShowItem.coverOriginal}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="movie-content__poster__img_tv_show"
                      src={tvShowItem.cover}
                      alt={tvShowItem.title || tvShowItem.name}
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="movie-content-description">
            {tvShowItem.originalTitle ? (
              <h3
                className="original-title"
                style={{
                  fontSize: getOriginalTitleFontSize(tvShowItem.originalTitle),
                }}
              >
                Original Title: {tvShowItem.originalTitle}
              </h3>
            ) : null}
            <p
              className="overview"
              style={{
                fontSize: '1rem',
              }}
            >
              {tvShowItem.description}
            </p>
          </div>

          <div className="detail-container seasons-list">
            <div className="media-section">
              <div className="media-section__header">
                <h2 className="subtitle">Seasons</h2>
              </div>
              <TVShowSeasonList
                tvShowID={tvShowItem.id}
                seasons={tvShowItem.seasons}
              />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              {tvShowItem.tvShowGenres.length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Genres'}</h2>
                  </div>
                  <div className="genres">
                    {tvShowItem.tvShowGenres.map(
                      (tvShowGenre: any, i: number) => (
                        <Link
                          className="media-a"
                          to={'/tv-shows/?genre=' + tvShowGenre.genre.label}
                          key={i}
                        >
                          <span className="badge genres__item">
                            {tvShowGenre.genre.label}
                          </span>
                        </Link>
                      ),
                    )}
                  </div>
                </Fragment>
              )}

              {tvShowItem.tvShowCountries.length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Countries'}</h2>
                  </div>
                  <div className="genres">
                    {tvShowItem.tvShowCountries.map(
                      (tvShowCountry: any, i: number) => (
                        <Link
                          className="media-a"
                          to={
                            '/tv-shows/?country=' + tvShowCountry.country.label
                          }
                          key={i}
                        >
                          <span className="badge genres__item">
                            {tvShowCountry.country.label}
                          </span>
                        </Link>
                      ),
                    )}
                  </div>
                </Fragment>
              )}
            </div>
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Additional Information'}</h2>
              </div>

              <div className="genres">
                <span className="badge genres__item">
                  IMDB Rating: {tvShowItem.imdbRating}
                </span>
              </div>

              <div className="genres">
                <OutlineButton
                  onClick={() =>
                    window.open(tvShowItem.backgroundOriginal, '_blank')
                  }
                  className="secondary-button"
                >
                  <img src={backgroundIcon} width="25" height="25" alt="" />
                </OutlineButton>
              </div>

              {tvShowItem.tags.length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Tags'}</h2>
                  </div>
                  <div className="genres">
                    {tvShowItem.tags.map((tag: any, i: number) => (
                      <Link
                        className="media-a"
                        // to={'/tv-shows/search/tag/' + tag.label}
                        to={'/tv-shows/?tag=' + tag.label}
                        key={i}
                      >
                        <span key={i} className="badge genres__item">
                          {tag.label}
                        </span>
                      </Link>
                    ))}
                  </div>
                </Fragment>
              )}
              {tvShowItem.collections.length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Collections'}</h2>
                  </div>
                  <div className="genres">
                    {tvShowItem.collections.map(
                      (collection: any, i: number) => (
                        <Link
                          className="media-a"
                          // to={
                          //   '/tv-shows/search/collection/' + collection.label
                          // }
                          to={'/tv-shows/?collection=' + collection.label}
                          key={i}
                        >
                          <span key={i} className="badge genres__item">
                            {collection.label}
                          </span>
                        </Link>
                      ),
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </div>

          <div className="detail-container">
            <div className="media-section mt-3">
              <div className="media-section__header">
                <h2 className="subtitle">Similar</h2>
              </div>
              {renderSimilarItems()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
