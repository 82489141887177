import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useEffect } from 'react';
import {
  resetEpisodeState,
  setCurrentTvShowSeasonEpisodeStep,
  setTvShowSeasonEpisodesData,
} from '../../../../app/slices/addTvShowSeasonEpisodeSlice';

export const Step9 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    newTvShowSeasonEpisodeUlid,
    tvShowSeasonEpisodesData,
    selectedTvShowSeasonEpisode,
  } = useAppSelector((state: RootState) => state.addTvShowSeasonEpisode);

  const addMoreEpisodesSetup = () => {
    // let updatedTvShowSeasonEpisodesData = tvShowSeasonEpisodesData
    const updatedTvShowSeasonEpisodesData = tvShowSeasonEpisodesData.map(
      (episode: any) =>
        episode.imdbId === selectedTvShowSeasonEpisode
          ? { ...episode, alreadyAdded: true }
          : episode,
    );
    dispatch(setTvShowSeasonEpisodesData(updatedTvShowSeasonEpisodesData));
    dispatch(setCurrentTvShowSeasonEpisodeStep(2));
  };

  const finalSetup = () => {
    navigate(`/tv-shows/season/episode/${newTvShowSeasonEpisodeUlid}`);
    dispatch(resetEpisodeState());
  };
  return (
    <div className="add-media-form-container">
      <div className="buttons-container">
        <Button onClick={() => addMoreEpisodesSetup()}>
          Add More Episodes
        </Button>
        <Button onClick={() => finalSetup()}>Go To New Episode Page</Button>
      </div>
    </div>
  );
};
