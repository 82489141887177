import './TVShowSeasonList.scss';

/* eslint-disable import/no-unresolved */
import { SwiperSlide, Swiper } from 'swiper/react';

import { TVShowSeasonCard } from '../../components';
import SwiperCore from 'swiper/core';
import { Keyboard, Mousewheel } from 'swiper/modules';

export const TVShowSeasonList = (props: any) => {
  SwiperCore.use([Keyboard, Mousewheel]);
  return (
    <div className="tv-show-season-list">
      <Swiper
        grabCursor={true}
        spaceBetween={10}
        slidesPerView={'auto'}
        mousewheel={false}
        keyboard={true}
      >
        {props.seasons &&
          props.seasons.map((item: any, i: number) => (
            <SwiperSlide key={i}>
              <TVShowSeasonCard tvShowID={props.tvShowID} item={item} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
