import './PageHeader.scss';
import { IPageHeader } from '../../interfaces/components';
import { useGetRandomMovieQuery } from '../../app/services/movieServerApi';
import { useGetRandomEpisodeQuery } from '../../app/services/tvShowServerApi';
import { useGetRandomAutographQuery } from '../../app/services/autographServerApi';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

export const PageHeader = ({ title }: IPageHeader) => {
  const location = useLocation();

  const [skipGetRandomMovieItem, setSkipGetRandomMovieItem] = useState(true);
  const [skipGetRandomEpisodeItem, setSkipGetRandomEpisodeItem] =
    useState(true);
  const [skipGetRandomAutographItem, setSkipGetRandomAutographItem] =
    useState(true);

  const newMovieBackground = useAppSelector(
    (state: RootState) => state.addMedia.newMovieBackground,
  );

  const newMovieBackgroundVerticalPosition = useAppSelector(
    (state: RootState) => state.addMedia.newMovieBackgroundVerticalPosition,
  );

  const newEpisodeBackground = useAppSelector(
    (state: RootState) => state.addTvShowSeasonEpisode.newEpisodeBackground,
  );

  const newEpisodeBackgroundVerticalPosition = useAppSelector(
    (state: RootState) =>
      state.addTvShowSeasonEpisode.newEpisodeBackgroundVerticalPosition,
  );

  const newAutographBackground = useAppSelector(
    (state: RootState) => state.addAutograph.newAutographBackground,
  );

  const newAutographBackgroundVerticalPosition = useAppSelector(
    (state: RootState) =>
      state.addAutograph.newAutographBackgroundVerticalPosition,
  );

  const {
    data: movieItem = undefined,
    // isLoading: isLoadingMovieItem,
    // // isFetching,
    // isError: isErrorMovieItem,
    // error: errorMovieItem,
  } = useGetRandomMovieQuery(
    { mode: 'background' },
    {
      skip: skipGetRandomMovieItem,
    },
  );
  const {
    data: episodeItem = undefined,
    // isLoading: isLoadingEpisodeItem,
    // // isFetching,
    // isError: isErrorEpisodeItem,
    // error: errorEpisodeItem,
  } = useGetRandomEpisodeQuery(
    { mode: 'background' },
    {
      skip: skipGetRandomEpisodeItem,
    },
  );
  const {
    data: autographItem = undefined,
    // isLoading: isLoadingAutographItem,
    // // isFetching,
    // isError: isErrorAutographItem,
    // error: errorAutographItem,
  } = useGetRandomAutographQuery(
    { mode: 'background' },
    {
      skip: skipGetRandomAutographItem,
    },
  );

  let randomItem = undefined;

  if (location.pathname.includes('autographs')) {
    randomItem = autographItem?.header;
  } else if (location.pathname.includes('tv-shows')) {
    randomItem = episodeItem?.header;
  } else if (!location.pathname.includes('add-media')) {
    randomItem = movieItem?.header;
  }

  useEffect(() => {
    if (location.pathname.includes('autographs')) {
      setSkipGetRandomAutographItem(false);
    } else if (location.pathname.includes('tv-shows')) {
      setSkipGetRandomEpisodeItem(false);
    } else if (!location.pathname.includes('add-media')) {
      setSkipGetRandomMovieItem(false);
    }
  }, [location.pathname]);

  const [loaded, setLoaded] = useState(false);
  const handleLoad = () => {
    setLoaded(true);
  };

  const background = randomItem?.background
    ? randomItem?.background
    : randomItem?.cover
      ? randomItem?.cover
      : randomItem?.image;

  const newMediaBackground =
    newMovieBackground !== ''
      ? newMovieBackground
      : newEpisodeBackground !== ''
        ? newEpisodeBackground
        : newAutographBackground;
  const newMediaBackgroundVerticalPosition =
    newMovieBackground !== ''
      ? newMovieBackgroundVerticalPosition
      : newEpisodeBackground !== ''
        ? newEpisodeBackgroundVerticalPosition
        : newAutographBackgroundVerticalPosition;
  return (
    <div
      className="page-header"
      style={{
        backgroundImage: loaded
          ? `url(${background ?? newMediaBackground})`
          : undefined,
        backgroundPosition: `${randomItem?.backgroundHorizontalPosition ?? 50}% ${randomItem?.backgroundVerticalPosition ?? newMediaBackgroundVerticalPosition}%`,
      }}
    >
      <img
        src={background ?? newMediaBackground}
        alt=""
        onLoad={handleLoad}
        style={{ display: 'none' }}
      />
      <h2 className="media-h2">{title}</h2>
    </div>
  );
};
