import './TVShowSeasonCard.scss';
import { Link } from 'react-router-dom';
import { Button, LazyLoadBackgroundCard } from '../../components';

export const TVShowSeasonCard = (props: any) => {
  const item = props.item;
  const link = '/tv-shows/season/' + item.ulid;
  const background = item.cover;

  return (
    <Link className="media-a" to={link}>
      <LazyLoadBackgroundCard
        type={'tv-show-season'}
        background={background}
        className={'tv-show-season-card'}
      >
        <Button className="btn">
          <i className="bi bi-play-fill"></i>
        </Button>
      </LazyLoadBackgroundCard>
      <h3 className="media-h3">Season {item.number}</h3>
    </Link>
  );
};
