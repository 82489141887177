import './EpisodeCard.scss';
import { Link } from 'react-router-dom';
import { Button } from '../../components';
import documentImage from '../../assets/document_image.jpg';

export const DocumentCard = (props: any) => {
  const item = props.item;
  // const link = '/tv-shows/season/episode/' + item.ulid;
  const background = documentImage;
  console.log('ITEM:', item);
  return (
    // onClick={() => window.open(background, '_blank')}
    <Link
      className="media-a"
      to={item.file}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className="episode-card"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Button className="btn">
          <i className="bi bi-play-fill"></i>
        </Button>
      </div>
      <h3 className="media-h3">{item.type.label}</h3>
    </Link>
  );
};
