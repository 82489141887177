import './EpisodeCard.scss';
import { Link } from 'react-router-dom';
import { Button, LazyLoadBackgroundCard } from '../../components';

export const PhotoCard = (props: any) => {
  const item = props.item;
  // const link = '/tv-shows/season/episode/' + item.ulid;
  const background = item.image;
  console.log('ITEM:', item);
  return (
    // onClick={() => window.open(background, '_blank')}
    <Link
      className="media-a"
      to={item.imageOriginal}
      target="_blank"
      rel="noopener noreferrer"
    >
      {/* <div
        className="episode-card"
        style={{ backgroundImage: `url(${background})` }}
      > */}
      <LazyLoadBackgroundCard
        type={'autograph'}
        background={background}
        className={'episode-card'}
      >
        <Button className="btn">
          <i className="bi bi-play-fill"></i>
        </Button>
      </LazyLoadBackgroundCard>
      {/* </div> */}
      <h3 className="media-h3">{item.type.label}</h3>
    </Link>
  );
};
