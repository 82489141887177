import './EpisodeCard.scss';
import { Link } from 'react-router-dom';
import { Button, LazyLoadBackgroundCard } from '../../components';

export const EpisodeCard = (props: any) => {
  const item = props.item;
  const link = '/tv-shows/season/episode/' + item.ulid;
  const background = item.cover;

  return (
    <Link className="media-a" to={link}>
      <LazyLoadBackgroundCard
        type={'episode'}
        background={background}
        className={'episode-card'}
      >
        <Button className="btn">
          <i className="bi bi-play-fill"></i>
        </Button>
      </LazyLoadBackgroundCard>
      <h3 className="media-h3">{item.title}</h3>
    </Link>
  );
};
