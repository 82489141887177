import { Button, LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
// import { useNavigate } from 'react-router';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback,
} from 'reactstrap';
import { useAppDispatch } from '../../../../app/hooks';
import {
  setCurrentMovieStep,
  setNewMovieBackgroundVerticalPosition, //   setCurrentMovieStep,
  // setFinalMovieData,
} from '../../../../app/slices/addMediaSlice';
// import { RootState } from '../../../../app/store';
import {
  useGetMovieBackgroundQuery,
  usePatchMovieMutation,
} from '../../../../app/services/movieServerApi';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { IMovieStep8FormInput } from '../../../../interfaces/forms/IMovieStep8Form';
import { Fragment } from 'react';
import errorIcon from '../../../../assets/error.svg';

export const MovieStep8 = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    // data = {},
    isSuccess: isSuccessGetMovieBackground,
    isLoading: isLoadingGetMovieBackground,
    isFetching: isFetchingGetMovieBackground,
    isError: isErrorGetMovieBackground,
    error: errorGetMovieBackground,
  } = useGetMovieBackgroundQuery();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IMovieStep8FormInput>();

  const [patchMovie, { isLoading, isError, isSuccess, error }] =
    usePatchMovieMutation();

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1000,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  const onSubmit: SubmitHandler<IMovieStep8FormInput> = (formdata) => {
    formdata.step = 'background_position_movie_data';
    // console.log("form data:", formdata)
    patchMovie(formdata).then(function (result: any) {
      if (result && result.data) {
        // dispatch(setNewMovieId(result.data.movieId));
        dispatch(setCurrentMovieStep(9));
      }
    });
  };

  return (
    <Fragment>
      {isLoadingGetMovieBackground || isFetchingGetMovieBackground ? (
        <div className="list-no-item-add">
          <LoadingSpinner />
        </div>
      ) : isErrorGetMovieBackground ? (
        <div className="list-no-item-add">
          <img src={errorIcon} alt="error-icon" />
          <p>{errorGetMovieBackground?.error}</p>
        </div>
      ) : isSuccessGetMovieBackground ? (
        <div className="big-form-container">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="backgroundVerticalPosition">
                    Background Vertical Position *
                  </Label>
                  <Controller
                    name="backgroundVerticalPosition"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="backgroundVerticalPosition"
                        placeholder="Enter the movie's background vertical position"
                        type="number"
                        step=".01"
                        invalid={!!errors.backgroundVerticalPosition}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          dispatch(
                            setNewMovieBackgroundVerticalPosition(
                              e.target.value,
                            ),
                          );
                          // console.log(e.target.value);
                        }}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The movie's background vertical position is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        </div>
      ) : null}
    </Fragment>
  );
};
