import '../AddMedia.scss';
import {
  MovieStep1,
  MovieStep2,
  MovieStep3,
  MovieStep4,
  MovieStep5,
  MovieStep6,
  MovieStep7,
  MovieStep8,
  MovieStep9,
  MovieStep10,
  MovieStep11,
  MovieStep12,
} from './Steps';
import { RootState } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';

export const AddMovie = () => {
  const currentMovieStep = useAppSelector(
    (state: RootState) => state.addMedia.currentMovieStep,
  );
  return (
    <div className="add-movie-container">
      {currentMovieStep === 1 && <MovieStep1 />}
      {currentMovieStep === 2 && <MovieStep2 />}
      {currentMovieStep === 3 && <MovieStep3 />}
      {currentMovieStep === 4 && <MovieStep4 />}
      {currentMovieStep === 5 && <MovieStep5 />}
      {currentMovieStep === 6 && <MovieStep6 />}
      {currentMovieStep === 7 && <MovieStep7 />}
      {currentMovieStep === 8 && <MovieStep8 />}
      {currentMovieStep === 9 && <MovieStep9 />}
      {currentMovieStep === 10 && <MovieStep10 />}
      {currentMovieStep === 11 && <MovieStep11 />}
      {currentMovieStep === 12 && <MovieStep12 />}
    </div>
  );
};
