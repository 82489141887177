import { LazyLoadBackgroundCard } from '../LazyLoadBackgroundCard';
import './SelectableMovieCard.scss';

export const SelectableMovieCard = (props: any) => {
  return (
    <>
      {/* <div
        className={`selectable-movie-card ${props.selected ? 'selected' : null}`}
        style={{ backgroundImage: `url(${props.item.cover})` }}
      /> */}
      <LazyLoadBackgroundCard
        type={'movie'}
        background={props.item.cover}
        className={`selectable-movie-card ${props.selected ? 'selected' : null}`}
      />
      <h3 className="media-h3">{props.item.title}</h3>
    </>
  );
};
