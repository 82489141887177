import './ArtistCard.scss';
import { Button, LazyLoadBackgroundCard } from '../../components';
import basicUser2 from '../../assets/basic_user_2.png';
import { Link } from 'react-router-dom';

export const ArtistCard = (props: any) => {
  const artist =
    props.category === 'actor'
      ? props.item.actor
      : props.category === 'director'
        ? props.item.director
        : props.category === 'producer'
          ? props.item.producer
          : props.category === 'screenwriter'
            ? props.item.screenwriter
            : props.item.artist;
  const role = props.item?.role;

  const link = '/' + 'artists' + '/' + artist.ulid;

  return (
    <div>
      <Link className="media-a" to={link}>
        <LazyLoadBackgroundCard
          type={'artist'}
          background={artist.photo ? artist.photo : basicUser2}
          className={'artist-card'}
        >
          <Button className="btn">
            <i className="bi bi-play-fill"></i>
          </Button>
        </LazyLoadBackgroundCard>
        <h3 className="media-h3">{artist.name}</h3>
      </Link>
      {role && <h3 className="media-role">{role}</h3>}
    </div>
  );
};
