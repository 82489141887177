import './SelectableArtistCard.scss';
import basicUser2 from '../../assets/basic_user_2.png';
import { LazyLoadBackgroundCard } from '../LazyLoadBackgroundCard';

export const SelectableArtistCard = (props: any) => {
  return (
    <>
      {/* <div
        className={`selectable-movie-card ${props.selected ? 'selected' : null}`}
        style={{
          backgroundImage: `url(${props.item.imageUrl ? props.item.imageUrl : basicUser2})`,
        }}
      /> */}
      <LazyLoadBackgroundCard
        type={'artist'}
        background={props.item.imageUrl ? props.item.imageUrl : basicUser2}
        className={`selectable-movie-card ${props.selected ? 'selected' : null}`}
      />
      <h3 className="media-h3">{props.item.artistName}</h3>
      <h4 className="media-h4">{props.item.artistPresentation}</h4>
    </>
  );
};
