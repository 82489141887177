import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {} from //   setCurrentMovieStep,
// setFinalMovieData,
'../../../../app/slices/addMediaSlice';
import { RootState } from '../../../../app/store';
import { IStep17FormInput } from '../../../../interfaces/forms/AutographForm';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { setCurrentAutographStep } from '../../../../app/slices/addAutographSlice';
import { usePatchAutographMutation } from '../../../../app/services/autographServerApi';

export const Step17 = () => {
  const dispatch = useAppDispatch();

  const finalMovieData = useAppSelector(
    (state: RootState) => state.addMedia.finalMovieData,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep17FormInput>({
    defaultValues: finalMovieData,
  });

  const [patchAutograph, { isLoading, isError, isSuccess, error }] =
    usePatchAutographMutation();

  const accessLevels = useAppSelector(
    (state: RootState) => state.addAutograph.accessLevels,
  );

  // const {
  //   generalMovieData,
  //   secondaryMovieData,
  //   castCrewMovieData,
  //   imagesMovieData,
  //   languagesMovieData,
  //   chaptersMovieData,
  // } = useAppSelector((state: RootState) => state.addMedia);

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      Swal.fire({
        title: 'Autograph created succesfully',
        text: 'Redirecting to autograph page',
        icon: 'success',
        timer: 1000,
        showConfirmButton: false,
      });
    // Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  const onSubmit: SubmitHandler<IStep17FormInput> = (formdata) => {
    // dispatch(setFinalMovieData(formdata));
    // const fullMovieData = { ...generalMovieData, ...secondaryMovieData, ...castCrewMovieData, ...imagesMovieData, ...languagesMovieData, ...chaptersMovieData, ...formdata, mode: "new"}
    // // console.log('DATA MOVIE STEP 10:', fullMovieData);
    // createMovie(fullMovieData)
    formdata.step = 'final_autograph_data';
    patchAutograph(formdata).then(function (result: any) {
      if (result && result.data) {
        dispatch(setCurrentAutographStep(18));
      }
    });
  };

  return (
    <>
      <div className="media-section__subtitle">
        <h2 className="media-h2">Step 16</h2>
      </div>
      <div className="big-form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="accessLevel">Access Level *</Label>
                <Controller
                  name="accessLevel"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormSelect
                      field={field}
                      options={accessLevels}
                      placeholder="Select the autograph's access level"
                      error={errors.accessLevel}
                    />
                  )}
                />
                {errors.accessLevel && (
                  <div className="invalid-message">
                    {"The autograph's access level is required"}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                <Label for="editingCompleted">Editing Completed?</Label>
                <Controller
                  name={'editingCompleted'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      type="checkbox"
                      style={{ marginTop: '10px' }}
                      // {...field}
                      // // defaultChecked={field.value}
                      checked={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="comments">Comments</Label>
                <Controller
                  name="comments"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Input
                      id="comments"
                      placeholder="Enter the autograph's comments"
                      type="textarea"
                      invalid={!!errors.comments}
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Finish</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
