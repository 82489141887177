import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
// import { useNavigate } from 'react-router';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  setCurrentMovieStep,
  setNewMovieUlid, //   setCurrentMovieStep,
  // setFinalMovieData,
} from '../../../../app/slices/addMediaSlice';
import { RootState } from '../../../../app/store';
// import { useCreateMovieMutation } from '../../../../app/services/movieServerApi';
import { usePatchMovieMutation } from '../../../../app/services/movieServerApi';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { IMovieStep11FormInput } from '../../../../interfaces/forms/IMovieStep11Form';

export const MovieStep11 = () => {
  // const dispatch = useAppDispatch();

  const finalMovieData = useAppSelector(
    (state: RootState) => state.addMedia.finalMovieData,
  );
  const newMovieFilePath = useAppSelector(
    (state: RootState) => state.addMedia.newMovieFilePath,
  );
  // finalMovieData["filePath"] = newMovieFilePath
  const defaultValues = { ...finalMovieData, filePath: newMovieFilePath };

  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IMovieStep11FormInput>({
    // defaultValues: finalMovieData,
    defaultValues: defaultValues,
  });

  const [patchMovie, { isLoading, isError, isSuccess, error }] =
    usePatchMovieMutation();

  const accessLevels = useAppSelector(
    (state: RootState) => state.addMedia.accessLevels,
  );

  // const {
  //   generalMovieData,
  //   secondaryMovieData,
  //   castCrewMovieData,
  //   imagesMovieData,
  //   languagesMovieData,
  //   chaptersMovieData,
  // } = useAppSelector((state: RootState) => state.addMedia);

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      Swal.fire({
        title: 'Movie created succesfully',
        text: 'Redirecting to movie page',
        icon: 'success',
        timer: 1000,
        showConfirmButton: false,
      });
    // Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  const onSubmit: SubmitHandler<IMovieStep11FormInput> = (formdata) => {
    // dispatch(setFinalMovieData(formdata));
    // const fullMovieData = { ...generalMovieData, ...secondaryMovieData, ...castCrewMovieData, ...imagesMovieData, ...languagesMovieData, ...chaptersMovieData, ...formdata, mode: "new"}
    // // console.log('DATA MOVIE STEP 10:', fullMovieData);
    // createMovie(fullMovieData)
    formdata.step = 'final_movie_data';
    patchMovie(formdata).then(function (result: any) {
      if (result && result.data) {
        // dispatch(setNewMovieId(result.data.movieId));
        // dispatch(setCurrentMovieStep(10));
        // navigate(`/movies/${result.data.movieUlid}`);
        dispatch(setNewMovieUlid(result.data.movieUlid));
        dispatch(setCurrentMovieStep(12));
      }
    });
  };

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="accessLevel">Access Level *</Label>
              <Controller
                name="accessLevel"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={accessLevels}
                    placeholder="Select the movie's access level"
                    error={errors.accessLevel}
                  />
                )}
              />
              {errors.accessLevel && (
                <div className="invalid-message">
                  {"The movie's access level is required"}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="filePath">File Path *</Label>
              <Controller
                name="filePath"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="filePath"
                    placeholder="Enter the movie's file path"
                    type="text"
                    invalid={!!errors.filePath}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The movie's file path is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
              <Label for="editingCompleted">Editing Completed?</Label>
              <Controller
                name={'editingCompleted'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="checkbox"
                    style={{ marginTop: '10px' }}
                    // {...field}
                    // // defaultChecked={field.value}
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="comments">Comments</Label>
              <Controller
                name="comments"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="comments"
                    placeholder="Enter the movie's comments"
                    type="textarea"
                    invalid={!!errors.comments}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Finish</Button>
        </div>
      </Form>
    </div>
  );
};
