import './TVShowCard.scss';
import { Link } from 'react-router-dom';
import { ArtistTVShowModal, Button, LazyLoadBackgroundCard } from '../';
import { Fragment, useState } from 'react';

export const TVShowCard = (props: any) => {
  const item = props.item;
  const link = '/' + props.category + '/' + item.ulid;
  const background = item.cover;

  const [openArtistTVShowModal, setOpenArtistTVShowModal] = useState(false);
  const toggleArtistTVShowModal = () =>
    setOpenArtistTVShowModal(!openArtistTVShowModal);

  return props.selectedArtistId ? (
    <Fragment>
      <div style={{ cursor: 'pointer' }} role="button"               tabIndex={0}
              onKeyDown={toggleArtistTVShowModal}
              onClick={toggleArtistTVShowModal}>
        <LazyLoadBackgroundCard
          type={'tv-show'}
          background={background}
          className={'tv-show-card'}
        >
          <Button className="btn">
            <i className="bi bi-play-fill"></i>
          </Button>
        </LazyLoadBackgroundCard>
        <h3 className="media-h3">{item.title || item.name}</h3>
      </div>
      <ArtistTVShowModal
        isOpen={openArtistTVShowModal}
        toggle={toggleArtistTVShowModal}
        artistId={props.selectedArtistId}
        tvShowId={item.ulid}
      />
    </Fragment>
  ) : (
    <Link className="media-a" to={link}>
      <LazyLoadBackgroundCard
        type={'tv-show'}
        background={background}
        className={'tv-show-card'}
      >
        <Button className="btn">
          <i className="bi bi-play-fill"></i>
        </Button>
      </LazyLoadBackgroundCard>
      <h3 className="media-h3">{item.title || item.name}</h3>
    </Link>
  );
};
