import './EpisodeCard.scss';
import { Button, TrailerModal } from '../../components';
import { Fragment, useState } from 'react';
import movieCard from '../../assets/movie_card.png';

export const VideoCard = (props: any) => {
  const item = props.item;
  const background = item.thumbnail;
  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const toggleTrailerModal = () => setOpenTrailerModal(!openTrailerModal);

  const [loaded, setLoaded] = useState(false);
  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <Fragment>
      <div
        className="episode-card"
        style={{
          backgroundImage: loaded ? `url(${background})` : `url(${movieCard})`,
          // filter: loaded ? "blur(0em)" : "blur(0.2em)",
          // transition: "filter 0.5s",
          cursor: 'pointer',
        }}
        role="button"
        tabIndex={0}
        onKeyDown={toggleTrailerModal}
        onClick={toggleTrailerModal}
      >
        <img
          src={background}
          alt=""
          onLoad={handleLoad}
          style={{ display: 'none' }}
        />
        <Button className="btn">
          <i className="bi bi-play-fill"></i>
        </Button>
      </div>
      <h3 className="media-h3">{item.type.label}</h3>
      <TrailerModal
        isOpen={openTrailerModal}
        toggle={toggleTrailerModal}
        item={item}
      />
    </Fragment>
  );
};
